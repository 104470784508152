let sizeCategory = $("#sizeCategory").val();
var productLength = $(".single-product").length + 1;
var counter = 0;
$(".single-product").each(function () {
  counter++;
});
console.log(counter);
bindEventToSelectAGas(counter);
remove_product();
function reSortProductIndex() {
  let i = 1;
  /*
      $('.single-product:visible').each(function(){
          $(this).find('.product-index').text(i);
          i++;
      });
      */
  $(".single-product").each(function () {
    $(this).attr("id", "product-" + i);
    $(this).find(".product-index").text(i);
    $(this)
      .find(".remove-btn")
      .attr("id", "remove-btn" + i);
    $(this).find(".remove-btn").attr("data-id", i);
    i++;
  });
}
function bindEventToSelectAGas(length) {
  for (let i = 1; i < length + 1; i++) {
    $(document).on("change", "#gas" + i, function () {
      //$("#gas"+i).change(function() {
      if (
        $(this).val() == "coregas07" ||
        $(this).val() == "acetyleneInstrumentGrade" ||
        $(this).val() == "airInstrumentGrade" ||
        $(this).val() == "argon300Bar" ||
        $(this).val() == "corebrew30" ||
        $(this).val() == "coregas25" ||
        $(this).val() == "Hydrogen4_0" ||
        $(this).val() == "ethylene" ||
        $(this).val() == "helium4_6" ||
        $(this).val() == "shieldpro23" ||
        $(this).val() == "laspur216" ||
        $(this).val() == "laspur219" ||
        $(this).val() == "oxygen5_0" ||
        $(this).val() == "ripeningGas" ||
        $(this).val() == "shieldpro22" ||
        $(this).val() == "shieldpro30" ||
        $(this).val() == "shieldproHeavy" ||
        $(this).val() == "shieldproLight" ||
        $(this).val() == "shieldstainHeavy" ||
        $(this).val() == "shieldstainLight" ||
        $(this).val() == "shieldstainMedium" ||
        $(this).val() == "zeroAir" ||
        $(this).val() == "p10"
      ) {
        //G
        add_option(i);
        remove_option(i, "cyl_size_d");
        remove_option(i, "cyl_size_f");
        remove_option(i, "cyl_size_fivr");
        remove_option(i, "cyl_size_pack");
        remove_option(i, "cyl_size_module");
        remove_option_kg(i);
      } else if ($(this).val() == "lpg") {
        //9KG POL, 15KG Forklift, 19kg POL, 45KG POL
        add_option_kg(i);
        remove_option(i, "cyl_size_d");
        remove_option(i, "cyl_size_f");
        remove_option(i, "cyl_size_fivr");
        remove_option(i, "cyl_size_g");
        remove_option(i, "cyl_size_pack");
        remove_option(i, "cyl_size_module");
      } else if (
        $(this).val() == "argonIndustrial" ||
        $(this).val() == "coregas09"
      ) {
        //D, F, FIVR, G, Pack
        add_option(i);
        remove_option(i, "cyl_size_module");
        remove_option_kg(i);
      } else if (
        $(this).val() == "acetylene" ||
        $(this).val() == "balloonGas"
      ) {
        //D, F, G
        add_option(i);
        remove_option(i, "cyl_size_pack");
        remove_option(i, "cyl_size_module");
        remove_option(i, "cyl_size_fivr");
        remove_option_kg(i);
      } else if (
        $(this).val() == "carbonDioxideIndustrial" ||
        $(this).val() == "carbonDioxideFoodGrade" ||
        $(this).val() == "hydrogen5_0" ||
        $(this).val() == "nitrogenIndustrial" ||
        $(this).val() == "oxygenIndustrial"
      ) {
        //D, F, G, Pack
        add_option(i);
        remove_option(i, "cyl_size_module");
        remove_option(i, "cyl_size_fivr");
        remove_option_kg(i);
      } else if ($(this).val() == "argon5.0" || $(this).val() == "r744") {
        //D, G, Pack
        add_option(i);
        remove_option(i, "cyl_size_f");
        remove_option(i, "cyl_size_fivr");
        remove_option(i, "cyl_size_module");
        remove_option_kg(i);
      } else if ($(this).val() == "coregas18") {
        //F, FIVR, G, Pack
        add_option(i);
        remove_option(i, "cyl_size_d");
        remove_option(i, "cyl_size_module");
        remove_option_kg(i);
      } else if (
        $(this).val() == "carbonDioxideHighPurity" ||
        $(this).val() == "coregas5_2" ||
        $(this).val() == "nitrogen4_0" ||
        $(this).val() == "nitrogen5_0" ||
        $(this).val() == "nitrogenFoodGrade" ||
        $(this).val() == "Helium5_0" ||
        $(this).val() == "shieldpro32" ||
        $(this).val() == "shieldproMedium" ||
        $(this).val() == "shieldpurge"
      ) {
        //D, G;
        add_option(i);
        remove_option(i, "cyl_size_f");
        remove_option(i, "cyl_size_fivr");
        remove_option(i, "cyl_size_pack");
        remove_option(i, "cyl_size_module");
        remove_option_kg(i);
      } else if (
        $(this).val() == "corebrew20" ||
        $(this).val() == "coregasSsMig"
      ) {
        //F, G;
        add_option(i);
        remove_option(i, "cyl_size_d");
        remove_option(i, "cyl_size_fivr");
        remove_option(i, "cyl_size_pack");
        remove_option(i, "cyl_size_module");
        remove_option_kg(i);
      } else if (
        $(this).val() == "corebrew50" ||
        $(this).val() == "shieldpro24"
      ) {
        //G, Pack;
        add_option(i);
        remove_option(i, "cyl_size_d");
        remove_option(i, "cyl_size_f");
        remove_option(i, "cyl_size_fivr");
        remove_option(i, "cyl_size_module");
        remove_option_kg(i);
      } else if ($(this).val() == "corebrew75") {
        //F
        add_option(i);
        remove_option(i, "cyl_size_d");
        remove_option(i, "cyl_size_g");
        remove_option(i, "cyl_size_fivr");
        remove_option(i, "cyl_size_module");
        remove_option(i, "cyl_size_pack");
        remove_option_kg(i);
      } else if ($(this).val() == "laspur208") {
        //D
        add_option(i);
        remove_option(i, "cyl_size_f");
        remove_option(i, "cyl_size_fivr");
        remove_option(i, "cyl_size_g");
        remove_option(i, "cyl_size_module");
        remove_option(i, "cyl_size_pack");
        remove_option_kg(i);
      }
    });
  }
}

function remove_option_kg(indentifier) {
  if (
    $("#cylinderSize" + indentifier + " option[value='cyl_size_9kg_pol']")
      .length > 0
  ) {
    $(
      "#cylinderSize" + indentifier + " option[value='cyl_size_9kg_pol']"
    ).remove();
    sort_select_option(indentifier);
  }
  if (
    $("#cylinderSize" + indentifier + " option[value='cyl_size_15kg_forklift']")
      .length > 0
  ) {
    $(
      "#cylinderSize" + indentifier + " option[value='cyl_size_15kg_forklift']"
    ).remove();
    sort_select_option(indentifier);
  }
  if (
    $("#cylinderSize" + indentifier + " option[value='cyl_size_19kg_pol']")
      .length > 0
  ) {
    $(
      "#cylinderSize" + indentifier + " option[value='cyl_size_19kg_pol']"
    ).remove();
    sort_select_option(indentifier);
  }
  if (
    $("#cylinderSize" + indentifier + " option[value='cyl_size_45kg_pol']")
      .length > 0
  ) {
    $(
      "#cylinderSize" + indentifier + " option[value='cyl_size_45kg_pol']"
    ).remove();
    sort_select_option(indentifier);
  }
}
function remove_option(indentifier, option_value) {
  if (
    $("#cylinderSize" + indentifier + " option[value='" + option_value + "']")
      .length > 0
  ) {
    $(
      "#cylinderSize" + indentifier + " option[value='" + option_value + "']"
    ).remove();
    sort_select_option(indentifier);
  }
}
function add_option(indentifier) {
  if (
    $("#cylinderSize" + indentifier + " option[value='cyl_size_d']").length <= 0
  ) {
    $("#cylinderSize" + indentifier).append(
      $("<option>", {
        value: "cyl_size_d",
        text: "D",
      })
    );
  }
  if (
    $("#cylinderSize" + indentifier + " option[value='cyl_size_f']").length <= 0
  ) {
    $("#cylinderSize" + indentifier).append(
      $("<option>", {
        value: "cyl_size_f",
        text: "F",
      })
    );
  }
  if (
    $("#cylinderSize" + indentifier + " option[value='cyl_size_fivr']")
      .length <= 0
  ) {
    $("#cylinderSize" + indentifier).append(
      $("<option>", {
        value: "cyl_size_fivr",
        text: "FIVR",
      })
    );
  }
  if (
    $("#cylinderSize" + indentifier + " option[value='cyl_size_g']").length <= 0
  ) {
    $("#cylinderSize" + indentifier).append(
      $("<option>", {
        value: "cyl_size_g",
        text: "G",
      })
    );
  }
  if (
    $("#cylinderSize" + indentifier + " option[value='cyl_size_pack']")
      .length <= 0
  ) {
    $("#cylinderSize" + indentifier).append(
      $("<option>", {
        value: "cyl_size_pack",
        text: "Pack",
      })
    );
  }
  if (
    $("#cylinderSize" + indentifier + " option[value='cyl_size_module']")
      .length <= 0
  ) {
    $("#cylinderSize" + indentifier).append(
      $("<option>", {
        value: "cyl_size_module",
        text: "Module",
      })
    );
  }
  sort_select_option(indentifier);
}
function add_option_kg(indentifier) {
  if (
    $("#cylinderSize" + indentifier + " option[value='cyl_size_9kg_pol']")
      .length <= 0
  ) {
    $("#cylinderSize" + indentifier).append(
      $("<option>", {
        value: "cyl_size_9kg_pol",
        text: "9KG POL",
      })
    );
  }
  if (
    $("#cylinderSize" + indentifier + " option[value='cyl_size_15kg_forklift']")
      .length <= 0
  ) {
    $("#cylinderSize" + indentifier).append(
      $("<option>", {
        value: "cyl_size_15kg_forklift",
        text: "15KG Forklift",
      })
    );
  }
  if (
    $("#cylinderSize" + indentifier + " option[value='cyl_size_19kg_pol']")
      .length <= 0
  ) {
    $("#cylinderSize" + indentifier).append(
      $("<option>", {
        value: "cyl_size_19kg_pol",
        text: "19kg POL",
      })
    );
  }
  if (
    $("#cylinderSize" + indentifier + " option[value='cyl_size_45kg_pol']")
      .length <= 0
  ) {
    $("#cylinderSize" + indentifier).append(
      $("<option>", {
        value: "cyl_size_45kg_pol",
        text: "45KG POL",
      })
    );
  }

  sort_select_option(indentifier);
}

function sort_select_option(indentifier) {
  var options = $("#cylinderSize" + indentifier + " option");
  var arr = options
    .map(function (_, o) {
      return {
        t: $(o).text(),
        v: o.value,
      };
    })
    .get();
  arr.sort(function (o1, o2) {
    return o1.t > o2.t ? 1 : o1.t < o2.t ? -1 : 0;
  });
  options.each(function (i, o) {
    console.log(i);
    o.value = arr[i].v;
    $(o).text(arr[i].t);
  });
}

// add a new product when the "Add a product" button is clicked and change elements' ids
$(".add-btn").on("click", function () {
  var product_length = 0;
  $(".single-product").each(function () {
    product_length++;
  });
  productLength = product_length + 1;
  if (productLength === 10) {
    $(".add-btn").hide();
  }
  if (productLength < 11) {
    var $newProduct = $("#product-1")
      .clone()
      .prop("id", "product-" + productLength);
    $newProduct.find(".product-index").text(productLength);

    $newProduct.find('select[name="message[GAS][]"]').attr("value", "");
    $newProduct.find('input[name="message[QUANTITY][]"]').val("");
    $newProduct.find('input[name="message[PICKUP][]"]').val("");
    $newProduct
      .find('select[name="message[CYLINDERSIZE][]"]')
      .attr("value", "");

    $newProduct
      .find('select[name="message[GAS][]"]')
      .attr("id", "gas" + productLength);
    $newProduct
      .find('select[name="message[CYLINDERSIZE][]"]')
      .attr("id", "cylinderSize" + productLength);
    $newProduct.find(".remove-btn").attr("id", "remove-btn" + productLength);
    $newProduct.find(".remove-btn").attr("data-id", productLength);
    $newProduct
      .find('input[name="message[QUANTITY][]"]')
      .attr("id", "quantity" + productLength);
    $newProduct
      .find('input[name="message[PICKUP][]"]')
      .attr("id", "emptyPickUp" + productLength);
    $.each(
      $newProduct.find('select[name="cylinderSizePartial"]'),
      function (index, item) {
        $(this).attr({
          class: "partialSize" + productLength + parseFloat(index + 1),
          id: $(this).attr("id").slice(0, -1) + productLength,
        });
      }
    );
    $("#order-form-wrapper").append($newProduct);
    bindEventToSelectAGas(productLength);
    $newProduct.show();
    reSortProductIndex();
  }
});

function remove_product() {
  $("div").on("click", ".remove-btn", function () {
    var data_id = $(this).attr("data-id");
    var product_length = 0;
    $(".single-product").each(function () {
      product_length++;
    });
    productLength = product_length + 1;
    if (productLength < 10) {
      $(".add-btn").show();
    }
    if (productLength <= 2) {
      alert("You can not remove all products");
      return false;
    } else {
      $("#product-" + data_id).remove();
      reSortProductIndex();
      return false;
    }
  });
}

$(function () {
  var dtToday = new Date();

  var month = dtToday.getMonth() + 1;
  var day = dtToday.getDate() + 1;
  var year = dtToday.getFullYear();
  if (month < 10) month = "0" + month.toString();
  if (day < 10) day = "0" + day.toString();

  var maxDate = year + "-" + month + "-" + day;
  $("#order_delivery_date").attr("min", maxDate);
});

prevent_submission_for_no_products();
function prevent_submission_for_no_products() {
  $(".error-list li").hide();

  $("form").on("click", ".submit", function () {
    var error_list = false;
    var error_list_acc_num = false;
    var error_list_po_num = false;
    var error_list_order_by = false;
    var error_list_contact_num = false;
    var error_list_contact_email = false;
    var error_list_st_address = false;
    var error_list_suburb = false;
    var error_list_post_code = false;
    var error_list_date = false;
    var error_list_contact_email_valid = false;

    $("select[name='message[GAS][]']").each(function (index, item) {
      var val = $(item).val();
      var id = $(item).attr("id");

      if (val === null || val === "") {
        error_list = true;
        console.log(val);
      } else {
        error_list = false;
        return false;
        console.log("val");
      }
    });
    if (error_list === true) {
      $(".error-list").find(".error_product").show();
    } else {
      $(".error-list").find(".error_product").hide();
    }

    if (
      $("#order_account_number").val() === null ||
      $("#order_account_number").val() === ""
    ) {
      error_list_acc_num = true;
      $(".error-list").find(".error_acc_num").show();
    } else {
      $(".error-list").find(".error_acc_num").hide();
      error_list_acc_num = false;
    }

    if (
      $("#order_po_number").val() === null ||
      $("#order_po_number").val() === ""
    ) {
      error_list_po_num = true;
      $(".error-list").find(".error_po_num").show();
    } else {
      error_list_po_num = false;
      $(".error-list").find(".error_po_num").hide();
    }

    if (
      $("#order_order_made_by").val() === null ||
      $("#order_order_made_by").val() === ""
    ) {
      error_list_order_by = true;
      $(".error-list").find(".error_order_by").show();
    } else {
      error_list_order_by = false;
      $(".error-list").find(".error_order_by").hide();
    }

    if (
      $("#order_contact_number").val() === null ||
      $("#order_contact_number").val() === ""
    ) {
      error_list_contact_num = true;
      $(".error-list").find(".error_contact_num").show();
    } else {
      error_list_contact_num = false;
      $(".error-list").find(".error_contact_num").hide();
    }

    if (
      $("#order_contact_email").val() === null ||
      $("#order_contact_email").val() === ""
    ) {
      error_list_contact_email = true;
      $(".error-list").find(".error_contact_email").show();
    } else {
      error_list_contact_email = false;
      $(".error-list").find(".error_contact_email").hide();
    }

    if (
      check_if_the_submitted_email_is_correct(
        $("#order_contact_email").val()
      ) === false
    ) {
      error_list_contact_email_valid = true;
      $(".error-list").find(".error_contact_email_valid").show();
    } else {
      error_list_contact_email_valid = false;
      $(".error-list").find(".error_contact_email_valid").hide();
    }

    if (
      $("#order_street_address").val() === null ||
      $("#order_street_address").val() === ""
    ) {
      error_list_st_address = true;
      $(".error-list").find(".error_street_address").show();
    } else {
      error_list_st_address = false;
      $(".error-list").find(".error_street_address").hide();
    }

    if ($("#order_suburb").val() === null || $("#order_suburb").val() === "") {
      error_list_suburb = true;
      $(".error-list").find(".error_suburb").show();
    } else {
      error_list_suburb = false;
      $(".error-list").find(".error_suburb").hide();
    }

    if (
      $("#order_postcode").val() === null ||
      $("#order_postcode").val() === ""
    ) {
      error_list_post_code = true;
      $(".error-list").find(".error_postcode").show();
    } else {
      error_list_post_code = false;
      $(".error-list").find(".error_postcode").hide();
    }

    if (
      $("#order_delivery_date").val() === null ||
      $("#order_delivery_date").val() === ""
    ) {
      error_list_date = true;
      $(".error-list").find(".error_product_date").show();
    } else {
      error_list_date = false;
      $(".error-list").find(".error_product_date").hide();
    }

    if (
      error_list ||
      error_list_acc_num ||
      error_list_po_num ||
      error_list_order_by ||
      error_list_contact_num ||
      error_list_contact_email ||
      error_list_st_address ||
      error_list_suburb ||
      error_list_post_code ||
      error_list_date
    ) {
      return false;
    } else {
      return true;
    }
  });
}
function check_if_the_submitted_email_is_correct(email) {
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
}
/************** Recaptcha ****************/
window.onload = function () {
  var $recaptcha = document.querySelector("#g-recaptcha-response");

  if ($recaptcha) {
    $recaptcha.setAttribute("required", "required");
  }
};
/************** End of Recaptcha ****************/
