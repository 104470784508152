$(function () {

    // Object.assign polyfill from;
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign

    if (typeof Object.assign != 'function') {
        // Must be writable: true, enumerable: false, configurable: true
        Object.defineProperty(Object, "assign", {
            value: function assign(target, varArgs) { // .length of function is 2
                'use strict';
                if (target == null) { // TypeError if undefined or null
                    throw new TypeError('Cannot convert undefined or null to object');
                }

                var to = Object(target);

                for (var index = 1; index < arguments.length; index++) {
                    var nextSource = arguments[index];

                    if (nextSource != null) { // Skip over if undefined or null
                        for (var nextKey in nextSource) {
                            // Avoid bugs when hasOwnProperty is shadowed
                            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                                to[nextKey] = nextSource[nextKey];
                            }
                        }
                    }
                }
                return to;
            },
            writable: true,
            configurable: true
        });
    }

    // Production steps of ECMA-262, Edition 6, 22.1.2.1
    if (!Array.from) {
        Array.from = (function () {
            var toStr = Object.prototype.toString;
            var isCallable = function (fn) {
                return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
            };
            var toInteger = function (value) {
                var number = Number(value);
                if (isNaN(number)) {
                    return 0;
                }
                if (number === 0 || !isFinite(number)) {
                    return number;
                }
                return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
            };
            var maxSafeInteger = Math.pow(2, 53) - 1;
            var toLength = function (value) {
                var len = toInteger(value);
                return Math.min(Math.max(len, 0), maxSafeInteger);
            };

            // The length property of the from method is 1.
            return function from(arrayLike /*, mapFn, thisArg */ ) {
                // 1. Let C be the this value.
                var C = this;

                // 2. Let items be ToObject(arrayLike).
                var items = Object(arrayLike);

                // 3. ReturnIfAbrupt(items).
                if (arrayLike == null) {
                    throw new TypeError('Array.from requires an array-like object - not null or undefined');
                }

                // 4. If mapfn is undefined, then let mapping be false.
                var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
                var T;
                if (typeof mapFn !== 'undefined') {
                    // 5. else
                    // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
                    if (!isCallable(mapFn)) {
                        throw new TypeError('Array.from: when provided, the second argument must be a function');
                    }

                    // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    if (arguments.length > 2) {
                        T = arguments[2];
                    }
                }

                // 10. Let lenValue be Get(items, "length").
                // 11. Let len be ToLength(lenValue).
                var len = toLength(items.length);

                // 13. If IsConstructor(C) is true, then
                // 13. a. Let A be the result of calling the [[Construct]] internal method 
                // of C with an argument list containing the single item len.
                // 14. a. Else, Let A be ArrayCreate(len).
                var A = isCallable(C) ? Object(new C(len)) : new Array(len);

                // 16. Let k be 0.
                var k = 0;
                // 17. Repeat, while k < len… (also steps a - h)
                var kValue;
                while (k < len) {
                    kValue = items[k];
                    if (mapFn) {
                        A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
                    } else {
                        A[k] = kValue;
                    }
                    k += 1;
                }
                // 18. Let putStatus be Put(A, "length", len, true).
                A.length = len;
                // 20. Return A.
                return A;
            };
        }());
    }


    'use strict';

    general.init();
    slickSliders.init();
    storeLocator.init();
    gasFinder.init();
    serviceCenter.init();

    // Youtube player init
	var player;
	



    function onYouTubePlayerAPIReady() {
        player = new YT.Player('content_video');
    }    

});

var general = (function () {

    var _wwidth,
        _bootstrapSize,
        _self = {};

    function init() {
        bindEvents();
        checkIE();
        addBootstrapSize();
        smoothScroll();
        accordion();
        tabs();
        youTubeHelpers();
        menuSearch();
        mobileMenu()
        icheckStyles();
        selectStyles();
		sendContactForm();
		siteSelector();
        searchForm();
        $(window).load(function () {
            if ($('.store-list').length) {
                hasScroll($('.store-list'));
            }
        });
        MicroModal.init({
            disableScroll: true
        });
        gaEventTracking();
        ajaxFormSubmit();
        gasesFormSubmit();
    }
    function bindEvents() {
        // PREVENT DEFAULT ON ALL href="#" LINKS
        $('a[href="#"]').on('click', function (e) {
            e.preventDefault();
            return true;
        });

        // WINDOW RESIZE EVENTS
        $(window).on('resize', function () {
            checkBreakpointChange();
            addBootstrapSize();
        });
        $(window).on('bsResizeToxs', function () {
            productAccordion();
            footerAccordion();
        });

    }

    if (getCurrentBootstrapSize() == "xs") {
        productAccordion();
        footerAccordion();
        searchCategories();
        mobileMenuLevels();
    }
    if (getCurrentBootstrapSize() != "xs") {
        megaMenu();
    }
    $(window).on('bsResizeTosm', function () {
        resetProductAccordion();
    });

    function checkIE() {
        if (Function('/*@cc_on return document.documentMode===10@*/')()) {
            document.documentElement.className += ' ie10';
        }
        if (Function('/*@cc_on return document.documentMode===11@*/')()) {
            document.documentElement.className += ' ie11';
        }
    }

    function scrollTo(selector) {
        var offset = $(selector).offset();
        $('html,body').animate({
            scrollTop: offset.top
        }, 300);
    }

    function addBootstrapSize() {
        var bootstrapSize = getCurrentBootstrapSize();
        $('html').attr('data-bs-size', bootstrapSize);
    }

    function getCurrentBootstrapSize() {
        _wwidth = $(window).width();
        if (_wwidth >= 1200) {
            return 'lg';
        } else if (_wwidth >= 992) {
            return 'md';
        } else if (_wwidth >= 768) {
            return 'sm';
        } else {
            return 'xs';
        }
    }

    function checkBreakpointChange() {
        var previousSize = $('html').attr('data-bs-size');
        var newSize = getCurrentBootstrapSize();
        var triggerName = '';
        if (previousSize != newSize) {
            triggerName = 'bsResizeTo' + newSize;
            $('body').trigger('bsResize').trigger(triggerName);
        }
    }

    function smoothScroll() {
        // Smooth Scroll
        $('a[href*="#"]:not([href="#"]):not([data-toggle])').click(function () {
            // target id
            var target = $(this.hash);
            // target distance from top of document
            // :Bug: this is returning different value after scroll?
            var target_offset = target.offset();
            // set padding for top of window/viewport
            var offset = 50;
            if (getCurrentBootstrapSize() == 'xs') {
                offset = 80;
            }

            // Set constant speed
            // get difference bewtween offsets
            var this_offset = $(this).offset();
            var offset_diff = Math.abs(target_offset.top - this_offset.top);
            var base_speed = 800; // Time in ms per 1,000 pixels
            // update speed based on distance to scroll, i.e. if further, slow down...
            var speed = (offset_diff * base_speed) / 1000;
            // ... and if very short, set minimum
            if (speed <= 800) {
                speed = 800;
            }

            if (target.length) {
                var scrollhere = target_offset.top - offset;
                $('html,body').animate({
                    scrollTop: scrollhere
                }, speed, 'swing', function () {
                    // after animation finished -> focus on target content
                    $(target).attr('tabindex', -1).on('blur focusout', function () {
                        $(this).removeAttr('tabindex');
                    }).focus();
                });
                return false;
            }
        });
    }

    function youTubeHelpers() {
        // Inject YouTube API script
        var tag = document.createElement('script');
        tag.src = "//www.youtube.com/player_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        $('.play-button').on('click touch', function (ev) {
            var container = $(this).closest('.video-wrapper');
            container.removeClass('vid-init');
            $("#content_video")[0].src += "&autoplay=1";
            if (container.parent().hasClass('video-item')) {
                container.parent().addClass('video-playing');
            } else {
                container.find('.caption').fadeOut('fast');
            }
            ev.preventDefault();

        });

	}




    var inputs = $('#attachment');
    Array.prototype.forEach.call(inputs, function (input) {
        var label = input.closest('label'),
            labelVal = label.innerHTML;

        input.addEventListener('change', function (e) {
            var fileName = '';
            if (this.files && this.files.length > 1)
                fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
            else
                fileName = e.target.value.split('\\').pop();

            if (fileName)
                label.querySelector('span').innerHTML = fileName;
            else
                label.innerHTML = labelVal;
        });
    });

    function accordion() {
        var accordionItem = $('ul.accordion li');
        $.each(accordionItem, function () {
            $(this).on('click', 'a.heading', function () {
                $(this).parent().siblings().removeClass('open').find('.show-content').slideUp();
                if ($(this).parent().hasClass('open')) {
                    $(this).parent().removeClass('open').find('.show-content').slideUp();
                } else {
                    $(this).parent().addClass('open').find('.show-content').slideDown();
                }
            });
        });
    }

    function productAccordion() {
        var accordionItem = $('.panel .accordion-item');
        $.each(accordionItem, function () {
            $(this).on('click', 'a.heading', function () {
                $(this).closest('.panel').siblings().find('.accordion-item').removeClass('open').find('.show-content').slideUp();
                if ($(this).parent('.accordion-item').hasClass('open')) {
                    $(this).parent('.accordion-item').removeClass('open').find('.show-content').slideUp();
                } else {
                    $(this).parent('.accordion-item').addClass('open').find('.show-content').slideDown();
                }
            });
        });
    }

    function footerAccordion() {
        var accordionItem = $('nav.navfooter ul.nav li');
        $.each(accordionItem, function () {
            $(this).on('click', '> a', function () {
                $(this).parent().siblings().removeClass('open').find('.nav__level2').slideUp();
                if ($(this).parent().hasClass('open')) {
                    $(this).parent().removeClass('open').find('.nav__level2').slideUp();
                } else {
                    $(this).parent().addClass('open').find('.nav__level2').slideDown();
                }
            });
        });
    }

    function resetProductAccordion() {
        $('.panel .accordion-item').addClass('open').find('.show-content').show();
    }

    function tabs() {
        var panelTab = $('.panel-tab');
        $.each(panelTab, function () {
            $(this).on('click', 'a', function () {
                $(this).parent().siblings().removeClass('active');
                if (!$(this).parent().hasClass('active')) {
                    $(this).parent().addClass('active');
                    var panelShow = $(this).parent().data('tab');
                    $('.panel[data-panel=' + panelShow + ']').addClass('active');
                    $('.panel[data-panel=' + panelShow + ']').siblings().removeClass('active');
                }
            });
        });
    }

    function menuSearch() {
        var search = $('.search');
        var searchBtn = $('.search__submit');
        var toggleBtn = $('.search__toggle-btn');
        searchBtn.on('click', function (e) {
            e.preventDefault();
            if (search.hasClass('search--open')) {
                $("#menuSearchForm").submit();
            } else {
                search.addClass('search--open');
                $('body').addClass('search-open').removeClass('menu-open');
                $('.js-toggle-menu__target').removeClass('site-nav__bottom--open');
                search.find('input[type=search]').focus();
            }
        });
        toggleBtn.on('click', function (e) 
        {
            e.preventDefault();
            search.toggleClass('search--open');
            $('body').toggleClass('search-open');
        });
    }

    function mobileMenu() {
        var menu = $('.js-toggle-menu__target');
        var menuBtn = $('.js-toggle-menu__trigger');
        menuBtn.on('click', function () {
            if (menu.hasClass('site-nav__bottom--open')) {
                menu.removeClass('site-nav__bottom--open');
                $('body').removeClass('menu-open');
            } else {
                menu.addClass('site-nav__bottom--open');
                $('body').addClass('menu-open');
            }
        });
    }

    
    function mobileMenuLevels(){
        var openTriggers = document.querySelectorAll('.js-level__trigger-open');
        var closeTriggers = document.querySelectorAll('.js-level__trigger-close');
        
        var forEach = function (array, callback, scope) {
            for (var i = 0; i < array.length; i++) {
                callback.call(scope, i, array[i]); // passes back stuff we need
            }
        };
    
        forEach(openTriggers, function (index, el) {
            el.addEventListener('click', function (e) {
                e.preventDefault();
                el.closest('li').classList.add('mega-menu--active'); 
            });
        });
        forEach(closeTriggers, function (index, el) {
            el.addEventListener('click', function (e) {
                e.preventDefault();
                el.closest('li').classList.remove('mega-menu--active'); 
            });
        });
    }

    function megaMenu(){
        $('.mega-menu').on('focusin' ,function(e){
            $(this).addClass('mega-menu--active');
            document.body.classList.add('menu-open');
            $(this).on('mousedown', function(e){
                // Allow clicks if children
                if((!$(e.target).parents('.mega-menu__level-3').length) && (!$(e.target).closest('li').hasClass('mega-menu__items'))) {
                    e.preventDefault();
                    $(e.target).trigger('blur');
                }
            });
        });
        $('.mega-menu').on('focusout' ,function(e){
            $('.mega-menu--active').off('mousedown');
            if (!$(e.relatedTarget).parents('.mega-menu--active').length) {
                $('.mega-menu--active').removeClass('mega-menu--active');
                document.body.classList.remove('menu-open');
            }
        });
    }

    function icheckStyles() {
        $('input').iCheck({
            checkboxClass: 'icheckbox_square',
            radioClass: 'iradio_square',
            increaseArea: '20%' // optional
        });
    }

    function selectStyles() {
        $('.custom-select select').chosen({
            disable_search_threshold: 8
        });
    }

    function searchCategories() {
        $('.faqs-filter select').on('change', function () {
            window.location.href = $(this).find(":selected").data('url');
        });
    }

    function searchForm() {
        $('.search-results-form a').on('click', function (e) {
            e.preventDefault();
            $('.search-results-form').submit();
        });
    }

    function sendContactForm() {
        $('.contact-form a.button').on('click', function (e) {
            e.preventDefault();
            console.log('raaaah');
            var hp = $(this).closest('.contact-form').find('#yourCorrection');
            location.hash = 'contactForm';
			if(hp[0].value == '') {
				$(this).closest('.contact-form').submit();
			} else {
				console.log('SPAM!');
			}
        });
    }

    function hasScroll(element) {
        if (element[0].offsetHeight < element[0].scrollHeight) {
            element.addClass('has-scroll');
        } else {
            element.removeClass('has-scroll');
        }
    }

    function gaEventTracking() {
        // Allow default event behaviour on <a> so target=_blank is respected.
        // Store finder buttons and links
        $('a[href*="/store-finder"]').on('click', function (e) {
            // e.preventDefault();
            var href = this.href;
            var whence = 'in page';
            if ($(this).closest('nav.navmain').length) whence = 'global nav';
            if ($(this).closest('nav.navfooter').length) whence = 'footer';

            ga('send', 'event', 'Store Finder', 'click', whence, {
                transport: 'beacon',
                hitCallback: function () {
                    // window.location = href;
                }
            });

        });
        $('a[href*="/trade-n-go-gas-store-finder"]').on('click', function (e) {
            // e.preventDefault();
            var href = this.href;
            var whence = 'in page';
            if ($(this).closest('nav.navmain').length) whence = 'global nav';
            if ($(this).closest('nav.navfooter').length) whence = 'footer';

            ga('send', 'event', 'Store Finder', 'click', whence, {
                transport: 'beacon',
                hitCallback: function () {
                    // window.location = href;
                }
            });

        });


        // Login buttons
        $('a[href*="online.coregas.com"]').on('click', function (e) {
            // e.preventDefault();
            var href = this.href;
            var whence = 'in page';
            if ($(this).closest('nav.navmain').length) whence = 'global nav';
            if ($(this).closest('nav.navfooter').length) whence = 'footer';

            ga('send', 'event', 'Login link', 'click', whence, {
                transport: 'beacon',
                hitCallback: function () {
                    // window.location = href;
                }
            });
        });

        // // Download SDS
        // $('a[href*="msds.chemalert.com/default.aspx?code=10000040"]').on('click',function(e) {
        //   e.preventDefault();
        //   var href = this.href;
        //   var whence = 'in page';
        //   var target = e.target.target;
        //   if ($(this).closest('nav.navmain').length) whence = 'global nav';
        //   if ($(this).closest('nav.navfooter').length) whence = 'footer';
        //
        //   ga('send','event','Download SDS link', 'click', whence, {
        //     transport: 'beacon',
        //     hitCallback: function() {
        //       if (target === '_blank') {
        //         $("<a>").attr("href", href).attr("target", "_blank")[0].click();
        //       } else {
        //         window.location = href;
        //       }
        //     }
        //   });
        // });

        // Download SDS
        $('a[href*="msds.chemalert.com/default.aspx?code=10000040"]').on('click', function (e) {
            // e.preventDefault();
            var href = this.href;
            var whence = 'in page';
            if ($(this).closest('nav.navmain').length) whence = 'global nav';
            if ($(this).closest('nav.navfooter').length) whence = 'footer';

            ga('send', 'event', 'Download SDS link', 'click', whence, {
                transport: 'beacon',
                hitCallback: function () {
                    // window.location = href;
                }
            });
        });

        // Submit search on page
        $('form[action="/search/results"]').on('submit', function (e) {
            var self = this;
            e.preventDefault();

            var sstring = $(this).find('input[name="q"]').val();

            ga('send', 'event', 'Search', 'submit', sstring, {
                transport: 'beacon',
                hitCallback: function () {
                    self.submit();
                }
            });
        });

        // Click contact link
        $('a[href*="/contact"]').on('click', function (e) {
            // e.preventDefault();
            var href = this.href;
            var whence = 'in page';
            if ($(this).closest('nav.navmain').length) whence = 'global nav';
            if ($(this).closest('nav.navfooter').length) whence = 'footer';

            ga('send', 'event', 'Contact link', 'click', whence, {
                transport: 'beacon',
                hitCallback: function () {
                    // window.location = href;
                }
            });
        });

        // Submit contact form
        $('form.contact-form').validate();
        $('form.contact-form').on('submit', function (e) {
            var self = this;
            e.preventDefault();
            var sstring = $(this).find('input[name="q"]').val();
            ga('send', 'event', 'Contact form', 'submit', sstring, {
                transport: 'beacon',
                hitCallback: function () {
                    if($('form.contact-form').valid()) {
                        self.submit();
                    }
                }
            });
        });

        // Download PDF file
        $('a[href*=".pdf"]').on('click', function (e) {
            // e.preventDefault();
            var href = this.href;
            var url = $(this).attr('href');
            ga('send', 'event', 'Download PDF file', 'click', url, {
                transport: 'beacon',
                hitCallback: function () {
                    // window.location = href;
                }
            });
        });

        // App store link
        $('a[href*="itunes.apple.com"], a[href*="play.google.com"]').on('click', function (e) {
            // e.preventDefault();
            var href = this.href;
            var whichstore = '';
            if ($(this).attr('href').indexOf("itunes.apple.com") !== -1) whichstore = 'ios';
            if ($(this).attr('href').indexOf("play.google.com") !== -1) whichstore = 'android';

            ga('send', 'event', 'App store link', 'click', whichstore, {
                transport: 'beacon',
                hitCallback: function () {
                    // window.location = href;
                }
            });
        });

        // External link
        $('a:not([href*="coregas.com"])').on('click', function (e) {
            // e.preventDefault();
            var url = $(this).attr('href');
            if (url == '#' || $(this).hasClass('chosen-single')) return;
            ga('send', 'event', 'External link', 'click', url, {
                transport: 'beacon',
                hitCallback: function () {
                    // window.location = url;
                }
            });
        });

        // Storefinder Search
        $('.store-finder-form').on('submit', function (e) {
            var self = this;
            e.preventDefault();

            var sstring = $(this).find('input[name="query"]').val();

            var sfilter = $('input[name="storeType[]"]:checked').map(function () {
                return $(this).val()
            }).get()

            if (sfilter.length) sstring += ' filtered by ' + sfilter;

            ga('send', 'event', 'Storefinder search', 'submit', sstring, {
                transport: 'beacon',
                hitCallback: function () {
                    self.submit();
                }
            });
		});
	}
	
	function siteSelector() {
		//Localisation dropdown
		$('.change-site').on('focusin' ,function(e){
            $(this).addClass('active');
            document.body.classList.add('menu-open');
            $('.change-site').on('mousedown', function(e){
                if(e.target.tagName != 'A') {
                    e.preventDefault();
                    $('.change-site').trigger('blur');
                }
            });
        });
        $('.change-site').on('focusout' ,function(e){
            $('.change-site').off('mousedown');
            if (!$(e.relatedTarget).parents('.change-site').length) {
                $('.change-site').removeClass('active');
                document.body.classList.remove('menu-open');
            }
        });
	}

    function ajaxFormSubmit() {
        $('.ajax-submit').submit(function (e) {

            e.preventDefault();

            var fd = new FormData(document.getElementById('ask-pat-form'));

            $('.ajax-submit').addClass('loading');

            $.ajax({
                url: '/',
                type: 'POST',
                data: fd,
                processData: false,
                contentType: false,
                success: function (data) {
                    $('.ajax-submit').hide();
                    $('#faq-modal-title').hide();
                    $('.ajax-submit').siblings('.success').show();
                },
                error: function (data) {
                    console.log(data);
                }

            })
        });
    }

    function gasesFormSubmit() {
        $('.gas-finder-submit').submit(function (e) {

            e.preventDefault();

            var fd = new FormData(document.getElementById('gas-finder'));

            $('.gas-finder-submit').addClass('loading');

            $.ajax({
                url: '/mailer.php',
                type: 'POST',
                data: fd,
                processData: false,
                contentType: false,
                success: function (data) {
                    $('.gas-finder-submit').hide();
					$('.gas-finder-submit').siblings('.success').show();
					$('html, body').animate({ scrollTop: 0 });
                },
                error: function (data) {
                }

            })
        });
    }

    $('.helper-icon').on('click', function () {
        $('.tooltip').removeClass('active')
        $(this).siblings('.tooltip').toggleClass('active')
    })
    
    $('.close-tooltip, .filter-item label').on('click', function () {
        $('.tooltip').removeClass('active')
    })

    _self = {
        init: init,
        _bootstrapSize: getCurrentBootstrapSize
    };

    return _self;
}());


var storeLocator = (function () {

    var _wwidth,
        _bootstrapSize,
        _self = {};

    function init() {
        //storeFinderNavigation();
        searchStates();
        showMoreStores();
		bindEvents();
    }



    function getCurrentBootstrapSize() {
        _wwidth = $(window).width();
        if (_wwidth >= 1200) {
            return 'lg';
        } else if (_wwidth >= 992) {
            return 'md';
        } else if (_wwidth >= 768) {
            return 'sm';
        } else {
            return 'xs';
        }
    }

    function bindEvents() {
        $('input[name="storeType[]"]').on('ifChecked', function (event) {
            if (window.location.href.indexOf('query') > 1) $(this).parents('form').submit();
        })
    }

    function slideStore() {
        var scroll_to = $('.store-list').scrollTop() + $('.store.active').position().top;
        $('.store-list').animate({
            scrollTop: scroll_to
        });
    }

    function searchStates() {
        var searchInput = $('input[type=search]');
        $.each(searchInput, function () {
            var btn = $(this).parent().find('.search-submit');
            btn.on('focus', function () {
                btn.parent().addClass('focus');
            });
            btn.on('blur', function () {
                btn.parent().removeClass('focus');
            });
        });
    }


    function showMoreStores() {
        var listLength = $(".store-list li").size() - 1;
        var x = 3;
        $('li.show-more a').on('click', function () {
            x = (x + 5 <= listLength) ? x + 5 : listLength;
            $('li.show-me:lt(' + x + ')').slideDown();
            console.log(x, listLength);
            if (x >= listLength) {
                $('li.show-more').slideUp();
            }
        });
    }

    _self = {
        init: init,
        _bootstrapSize: getCurrentBootstrapSize
    };

    return _self;
}());


var slickSliders = (function () {

    var _wwidth,
        _bootstrapSize,
        _self = {};

    function init() {
        newItemSlider();
        marketingSlider();
        productSlider();
        homepageSlider();
        if (getCurrentBootstrapSize() == "xs") {
            relatedProductsSlider();
        } else {
            unslickSliders();
        }
    }

    function getCurrentBootstrapSize() {
        _wwidth = $(window).width();
        if (_wwidth >= 1200) {
            return 'lg';
        } else if (_wwidth >= 992) {
            return 'md';
        } else if (_wwidth >= 768) {
            return 'sm';
        } else {
            return 'xs';
        }
    }
    /* Home page Slider */

    function homepageSlider(){       
        $('.home-page-slider .item-carousel').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            speed: 300,           
            dots: true,
            arrows: false, 
            autoplay: false,
            autoplaySpeed: 4000,
            cssEase: 'linear',
            /*appendArrows: $('.home-page-slider .carousel-nav'),*/
            responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1, 
                    autoplay: true,
                    autoplaySpeed: 4000,
                    cssEase: 'linear',
                    dots: true,
                    arrows: false, 
                    /*appendArrows: $('.home-page-slider .carousel-nav'),*/
                    centerMode: true,
                    centerPadding: '30px'
                }
            }]
        });        
    }
    function newItemSlider() {
        $('.news-items .item-carousel').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            speed: 300,
            arrows: true,
            appendArrows: $('.news-items .carousel-nav'),
            responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                    appendDots: $('.news-items .carousel-nav'),
                    centerMode: true,
                    centerPadding: '30px'
                }
            }]
        });
    }

    function productSlider() {
        $('.product-carousel .item-carousel').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            speed: 300,
            arrows: true,
            dots: true,
            appendDots: $('.product-carousel .carousel-nav'),
            appendArrows: $('.product-carousel .carousel-arrows'),
            responsive: [{
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: true,
                        dots: true,
                        appendDots: $('.product-carousel .carousel-nav'),
                        centerMode: true,
                        centerPadding: '10px'
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }

    function marketingSlider() {
        $('.marketing-carousel ').each(function (idx, item) {
            var carouselId = "carousel" + idx;
            this.id = carouselId;
            $(this).find('.carousel').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true,
                appendDots: $('#' + carouselId + ' .carousel-nav')
            });
        });
    }

    function relatedProductsSlider() {
        $('.related-products').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            speed: 300,
            arrows: false,
            centerMode: true,
            centerPadding: '30px',
            dots: true,
            appendDots: $('.related-products + .carousel-nav')
        });
    }


    function unslickSliders() {
        $('.related-products.slick-initialized').slick('unslick');
    };




    $(window).on('bsResizeToxs', function () {
        relatedProductsSlider();
    });
    $(window).on('bsResizeTosm', function () {
        unslickSliders();
	});
	

    _self = {
        init: init,
        _bootstrapSize: getCurrentBootstrapSize
    };

    return _self;
}());
var gasFinder = (function () {

    var _self = {};

    function init() {
        populateDropdowns();
        certLevel();
    }

    var populateDropdowns = function () {
        var $dropdowns = $('.custom-select[data-url]');
        $.each($dropdowns, function () {
            var select = $(this).find('select');
            $.getJSON($(this).data('url'), function (data) {
                var options = [];
                for (var i = 0; i < data.length; i++) {
                    options.push('<option value="' + data[i].Label + '" >' + data[i].Label + '</option>')
                }
                var optionsHTML = options.join("");
                select.html('<option value=""></option>' + optionsHTML);
                select.trigger("chosen:updated");;
            });
        })
    }
    var certLevel = function () {
        $('#f_certification_level').on('change', function () {
            if ($(this).val() == 'No certificate required') {
                $('#f_preferred_certification_protocol').prop('disabled', true).val('No certificate required').trigger('chosen:updated');
                $('#f_measurement_uncertainty').prop('disabled', true).val('No certificate required').trigger('chosen:updated');
            } else {
                $('#f_preferred_certification_protocol').prop('disabled', false).val('').trigger('chosen:updated');
                $('#f_measurement_uncertainty').prop('disabled', false).val('').trigger('chosen:updated');
            }
        });
    }

    _self = {
        init: init
    };

    return _self;
}());

var serviceCenter = (function () {
    var _self = {},
        loginFormEl = document.querySelector('.login-form');
        
    function init() {
        if(loginFormEl) {
            loginForm();
        }
    }

    function loginForm(){
        
        loginFormEl.querySelector('.submit').addEventListener('click', function(e){
            e.preventDefault();
            loginFormEl.submit();
        });
    }

    _self = {
        init: init
    };

    return _self;
}());
